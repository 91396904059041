@use '@/styles/utils/mixins.scss' as *;
.ctaBannerWrapper {
  margin-top: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.75rem;

  @include tab() {
    margin-top: 5.125rem;
  }

  .ctaBannerContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 3.75rem;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .ctaBannerHeadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .offeringText {
    color: $primary;
    width: 100%;
    display: block;
    position: relative;
    white-space: nowrap;
    height: 4.875rem;

    @include tab() {
      height: 2.625rem;
    }

    .offeringTextItem {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      white-space: normal;
      transition: opacity 0.5s ease forwards;

      &.active {
        opacity: 1;
      }
    }
  }

  .ctaBannerTitle {
    display: flex;
    flex-direction: column;

    span {
      color: $dark;
    }

    @include tab() {
      text-align: center;
      align-items: center;
    }
  }

  .description {
    width: 100%;
    max-width: 50rem;

    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .bannerImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 33.75rem;

    @include mob() {
      height: 29.25rem;
    }
  }

  .bannerImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.1s ease;
  }

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
